import React from "react"
import styled from "styled-components"

import { SEO } from "../components"
import { PrivacyPolicyPage } from "../sections/privacy-policy"

const Disclaimer: React.FC = () => {
  return (
    <PageWrapper>
      <SEO
        title="Privacy Policy"
        description="Explore Workerslife's Privacy Policy for data protection and your rights under POPIA. Learn how we safeguard your personal information."
      />
      <PrivacyPolicyPage />
    </PageWrapper>
  )
}

export default Disclaimer

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 85px;
`
